import { render, staticRenderFns } from "./Emotion.vue?vue&type=template&id=3f2b2a04&scoped=true"
import script from "./Emotion.vue?vue&type=script&lang=js"
export * from "./Emotion.vue?vue&type=script&lang=js"
import style0 from "./Emotion.vue?vue&type=style&index=0&id=3f2b2a04&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f2b2a04",
  null
  
)

export default component.exports