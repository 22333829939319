<template>
  <div>
    <el-dialog
      title="群管理"
      :visible.sync="visible"
      width="50%"
      :before-close="handleClose"
    >
      <el-form :model="formData" label-width="115px">
        <el-form-item label="群成员禁言">
          <div
            style="display: flex; flex-direction: column; align-items: start"
          >
            <el-select
              v-model="formData.type"
              placeholder="请选择"
              @change="typeChange"
            >
              <el-option label="全员可发言" :value="0"></el-option>
              <el-option label="全员禁言" :value="1"></el-option>
            </el-select>
            <div>以下成员{{ formData.type == 0 ? "禁言" : "发言" }}</div>

            <div class="list">
              <div v-for="(item, index) in jinyan" :key="item.userId">
                <div
                  class="user-item"
                  @click="onClickUser(1, index)"
                  :class="{ 'active-item': index === jinyanIndex }"
                >
                  <head-image
                    :id="item.userId"
                    :name="item.showNickName"
                    :url="item.headImage"
                    :size="25"
                  >
                  </head-image>
                  <span style="margin-left: 5px">{{ item.showNickName }}</span>
                </div>
              </div>
            </div>
            <div>
              <el-button icon="el-icon-plus" @click="openUser(0)"></el-button>
              <el-button icon="el-icon-minus" @click="delUser(1)"></el-button>
            </div>
          </div>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item label="群管理员">
          <div
            style="display: flex; flex-direction: column; align-items: start"
          >
            <div class="list">
              <div v-for="(item, index) in guanliyuan" :key="item.userId">
                <div
                  class="user-item"
                  @click="onClickUser(2, index)"
                  :class="{ 'active-item': index === guanliyuanIndex }"
                >
                  <head-image
                    :id="item.userId"
                    :name="item.showNickName"
                    :url="item.headImage"
                    :size="25"
                  >
                  </head-image>
                  <span style="margin-left: 5px">{{ item.showNickName }}</span>
                </div>
              </div>
            </div>
            <div v-if="$store.state.userStore.userInfo.id == group.ownerId">
              <el-button icon="el-icon-plus" @click="openUser(1)"></el-button>
              <el-button icon="el-icon-minus" @click="delUser(2)"></el-button>
            </div>
          </div>
        </el-form-item>
        <el-divider
          v-if="$store.state.userStore.userInfo.id == group.ownerId"
        ></el-divider>
        <el-form-item
          label="转让群主"
          v-if="$store.state.userStore.userInfo.id == group.ownerId"
        >
          <div
            style="display: flex; flex-direction: column; align-items: start"
          >
            <div>
              <el-button @click="openUser(2)">转让群主</el-button>
            </div>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>

    <div v-if="userVisible" class="modal">
      <div class="modal-content">
        <div class="modal-body">
          <div class="modal-left">
            <el-input placeholder="请输入搜索" v-model="searchText" />
            <el-checkbox-group
              v-model="checkUser"
              @change="onCheckUser"
              v-if="[0, 1].includes(selectType)"
            >
              <div style="display: flex; flex-direction: column">
                <div
                  v-for="item in groupMembers"
                  :key="item.userId"
                  style="display: flex; align-items: center"
                >
                  <el-checkbox :label="item.userId" v-show="!item.quit && item.showNickName.includes(searchText)">
                    <div class="user-item">
                      <head-image
                        :id="item.userId"
                        :name="item.showNickName"
                        :url="item.headImage"
                        :size="25"
                      >
                      </head-image>
                      <span style="margin-left: 5px">{{
                        item.showNickName
                      }}</span>
                    </div>
                  </el-checkbox>
                </div>
              </div>
            </el-checkbox-group>

            <el-radio-group
              v-model="radioUser"
              @change="onRadioUserChange"
              v-else
            >
              <div v-for="item in groupMembers" :key="item.userId">
                <el-radio
                  :label="item.userId"
                  style="display: flex; align-items: center"
                  v-show="!item.quit && item.showNickName.includes(searchText)"
                >
                  <div class="user-item">
                    <head-image
                      :id="item.userId"
                      :name="item.showNickName"
                      :url="item.headImage"
                      :size="25"
                    >
                    </head-image>
                    <span style="margin-left: 5px">{{
                      item.showNickName
                    }}</span>
                  </div>
                </el-radio>
              </div>
            </el-radio-group>
          </div>

          <div class="modal-right">
            <div>
              <span v-if="selectUser && selectUser.length == 0">
                请选择联系人
              </span>
              <span v-else> 已选择{{ selectUser.length }}位联系人 </span>
            </div>
            <span class="close" @click="closeModal()">&times;</span>
            <div>
              <div v-for="item in selectUser" :key="item.userId">
                <div class="user-item">
                  <head-image
                    :id="item.userId"
                    :name="item.showNickName"
                    :url="item.headImage"
                    :size="25"
                  >
                  </head-image>
                  <span style="margin-left: 5px">{{ item.showNickName }}</span>

                  <span class="delUser" @click="onDelUser(item)">
                    <i class="el-icon-close"></i>
                  </span>
                </div>
              </div>
            </div>

            <div class="operation-btn">
              <el-button @click="closeModal()">取消</el-button>
              <el-button
                :disabled="selectUser && selectUser.length === 0"
                type="primary"
                @click="onSave()"
                >保存</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeadImage from "../common/HeadImage.vue";
export default {
  name: "chatGroupManage",
  components: {
    HeadImage,
  },
  data() {
    return {
      visible: false,
      userVisible: false, //选择人员弹窗
      formData: {
        type: 0,
      },
      checkUser: [], //多选选中的人员
      radioUser: "", //单选选中的人
      group: {},
      groupMembers: [], //群成员
      selectUser: [], //选中的人员
      jinyan: [], //禁言人员
      guanliyuan: [], //管理员
      selectType: 0, // 选中类别
      jinyanIndex: "", //禁言人员选中的索引
      guanliyuanIndex: "", //管理员选中的索引
      searchText:"",
    };
  },
  methods: {
    handleClose() {
      this.visible = false;
      this.jinyan = [];
      this.guanliyuan = [];
      this.searchText = "";
    },
    typeChange(e) {
      if (e == 1) {
        this.$confirm("启用后,仅群主、群管理员和指定的成员可以发言?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$http({
              url: "/group/stopSpeak",
              method: "post",
              data: {
                groupId: this.group.id,
                type: 1,
                userIds: [],
              },
            }).then((res) => {
              this.$message.success("操作成功");
              this.getJinYanMenbers();
              this.$emit("refersh");
            });
          })
          .catch(() => {});
      } else {
        this.$http({
          url: "/group/stopSpeak",
          method: "post",
          data: {
            groupId: this.group.id,
            type: 0,
            userIds: [],
          },
        }).then((res) => {
          this.$message.success("操作成功");
          this.getJinYanMenbers();
          this.$emit("refersh");
        });
      }
    },
    openUser(e) {
      this.selectType = e;
      this.getGroupMembers();
      this.userVisible = true;
    },
    delUser(e) {
      if (e === 1) {
        if (
          this.jinyanIndex === "" ||
          this.jinyanIndex === undefined ||
          this.jinyanIndex === null
        ) {
          return;
        }

        this.jinyan.splice(this.jinyanIndex, 1);
        this.setJinyan();
        this.jinyanIndex = "";
      } else {
        if (
          this.guanliyuanIndex === "" ||
          this.guanliyuanIndex === undefined ||
          this.guanliyuanIndex === null
        ) {
          return;
        }
        this.guanliyuan.splice(this.guanliyuanIndex, 1);
        this.setManage();
        this.guanliyuanIndex = "";
      }
    },
    onClickUser(type, index) {
      if (type == 1) {
        this.jinyanIndex = index;
      } else {
        this.guanliyuanIndex = index;
      }
    },
    onCheckUser(user) {
      this.selectUser = user.map((m) => {
        return this.groupMembers.find((item) => item.userId == m);
      });

      //  console.log(this.selectUser,'选择的人员');
    },
    onRadioUserChange(e) {
      const info = this.groupMembers.find((item) => item.userId == e);
      this.selectUser = [info];
    },
    onDelUser(e) {
      const index = this.checkUser.findIndex((item) => item == e.userId);
      this.checkUser.splice(index, 1);
      const index2 = this.selectUser.findIndex(
        (item) => item.userId == e.userId
      );
      this.selectUser.splice(index2, 1);
      this.radioUser = "";
    },
    closeModal() {
      this.userVisible = false;
      this.groupMembers = [];
      this.checkUser = [];
      this.selectUser = [];
      this.radioUser = "";
      this.searchText = "";
    },
    setManage() {
      this.guanliyuan = [...this.guanliyuan, ...this.selectUser];
      this.$http({
        url: `/group/setManager`,
        method: "post",
        data: {
          groupId: this.group.id,
          managerIds: this.guanliyuan.map((item) => item.userId),
        },
      }).then(() => {
        this.$emit("refersh");
        this.$message.success("操作成功");
        this.getGuanliyuanMembers();
        this.getGroupMembers();
        this.closeModal();
      });
    },
    setQunzhu() {
      this.$http({
        url: `/group/transferOwner`,
        method: "post",
        data: {
          groupId: this.group.id,
          ownerId: this.selectUser[0].userId,
        },
      }).then(() => {
        this.$emit("refersh");
        this.$message.success("操作成功");
        this.closeModal();
        this.handleClose();
      });
    },
    setJinyan() {
      this.jinyan = [...this.jinyan, ...this.selectUser];
      const data = {
        groupId: this.group.id,
        userIds: this.jinyan.map((item) => item.userId),
        type: this.formData.type,
      };
      this.$http({
        url: "/group/stopSpeak",
        method: "post",
        data,
      }).then(() => {
        this.$emit("refersh");
        this.$message.success("操作成功");
        this.getGuanliyuanMembers();
        this.closeModal();
      });
    },
    onSave() {
      switch (this.selectType) {
        case 0:
          this.setJinyan();
          break;
        case 1:
          this.setManage();
          break;
        case 2:
          this.setQunzhu();
          break;
      }
    },
    getGroupMembers() {
      this.$http({
        url: `/group/members/${this.group.id}`,
        method: "get",
      }).then((members) => {
        const info = members.find(item=>item.userId == this.group.ownerId)
        const arr = [this.jinyan, this.guanliyuan, [info]][this.selectType];
        const ids = arr.map((item) => item.userId);
        this.groupMembers =
          members.filter((item) => !ids.includes(item.userId) && !item.quit) ||
          [];
      });
    },
    getJinYanMenbers() {
      this.$http({
        url: `/group/speak/${this.group.id}`,
        method: "get",
      }).then((res) => {
        this.jinyan = res;
      });
    },
    getGuanliyuanMembers() {
      this.$http({
        url: `/group/manager/${this.group.id}`,
        method: "get",
      }).then((res) => {
        this.guanliyuan = res;
      });
    },
    open(e) {
      this.group = e;
      this.visible = true;
      this.formData.type = this.group.isSpeak;
      this.getGroupMembers();
      this.getJinYanMenbers();
      this.getGuanliyuanMembers();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__label {
  font-size: 16px;
  font-weight: bold;
}

::v-deep .el-checkbox__inner {
  border-radius: 0 !important;
}

.custom-dialog {
  all: initial !important;
}

.list {
  width: 500px;
  height: 200px;
  overflow: auto;
  border: 1px solid #ccc;
}

/* 弹窗背景 */
.modal {
  //   display: none; /* 默认隐藏 */
  position: fixed; /* 固定定位 */
  z-index: 99999; /* 置于顶层 */
  left: 0;
  top: 0;
  width: 100%; /* 宽度100% */
  height: 100%; /* 高度100% */
  overflow: auto; /* 如果需要滚动 */
  //   background-color: rgb(0,0,0); /* 背景色 */
  //   background-color: rgba(0,0,0,0.4); /* 黑色背景，带透明度 */
}

/* 弹窗内容 */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% 从顶部，水平居中 */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  width: 45%; /* 宽度 */
  text-align: left;
}

/* 关闭按钮 */
.close {
  color: #aaa;
  //   float: right;
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  top: -10px;
  right: 0;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.user-item {
  display: flex;
  align-items: center;
  position: relative;
  margin: 2px 0;
}

.active-item {
  background-color: skyblue;
}

.modal-body {
  display: flex;

  .modal-left {
    flex: 1;
    background-color: #f6f6f7;
    padding: 10px 5px;
    height: 500px;
    overflow: auto;
  }

  .modal-right {
    flex: 1;
    position: relative;
    padding: 10px 5px;
    height: 500px;
    overflow: auto;
  }

  .delUser {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }

  .operation-btn {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
</style>
